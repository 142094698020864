import { createAction } from '@reduxjs/toolkit';
import type {
  CashRegistryItemView,
  WRegistryCollectionItemView,
  WRegistryCollectionItemViewResponse,
  WRegistryCollectionSearchView,
} from '@zola/svc-web-api-ts-client';

const REQUEST_ADD_TO_REGISTRY = 'zola/registryCollection/REQUEST_ADD_TO_REGISTRY';
const RECEIVE_ADD_TO_REGISTRY = 'zola/registryCollection/RECEIVE_ADD_TO_REGISTRY';
const REQUEST_SEARCH = 'zola/registryCollection/REQUEST_SEARCH';
const RECEIVE_SEARCH = 'zola/registryCollection/RECEIVE_SEARCH';
const SELECT_COLLECTION = 'zola/registryCollection/SELECT_COLLECTION';

export type ReceiveAddToRegistryType = {
  type: typeof RECEIVE_ADD_TO_REGISTRY;
  payload: WRegistryCollectionItemViewResponse;
};

export const requestAddToRegistry = createAction(REQUEST_ADD_TO_REGISTRY);
export const receiveAddToRegistry = createAction<
  WRegistryCollectionItemView | CashRegistryItemView | undefined
>(RECEIVE_ADD_TO_REGISTRY);

export const requestSearch = createAction(REQUEST_SEARCH);
export const receiveSearch = createAction<WRegistryCollectionSearchView>(RECEIVE_SEARCH);

export const selectCollection = createAction<unknown>(SELECT_COLLECTION);
