import { CartItemType } from '@zola-helpers/client/dist/es/@types/cart';
import { ConvertWebApiDeclaredToActual } from '@zola-helpers/client/dist/es/@types/svc-web-api';
import type {
  CheckoutData,
  Details,
  WGetCartView,
  WCreditCardView,
} from '@zola/svc-web-api-ts-client';
import { Reducer } from 'redux';
import type { CamelCasedPropertiesDeep } from 'type-fest';
import {
  receiveCartAction,
  receivedSavedCreditCardsAction,
  receiveDeletedItemsAction,
  receiveInitialCartDetailsAction,
  receiveShippingValidationAction,
  receiveUpdatedCartAction,
  requestCartAction,
  requestUpdatedCartAction,
  receiveRemoveDefaultCreditCard,
} from '../actions/types/CartActionTypes';

export type Promo = {
  code?: string | null;
  label?: string | null;
  terms?: string | null;
};
export type CartState = {
  busy: boolean;
  cartId?: string | null;
  cartMessage?: string | null;
  checkoutData?: CamelCasedPropertiesDeep<ConvertWebApiDeclaredToActual<CheckoutData>> | null;
  details?: CamelCasedPropertiesDeep<ConvertWebApiDeclaredToActual<Details>> | null;
  freeShipping?: { amountLeft?: number | null; metThreshold?: boolean | null } | null;
  initialized: boolean;
  items: CartItemType[];
  promo?: Promo | null;
  /** @deprecated - this doesnt' appear to be read anywhere */
  size: number;
  totals: {
    subtotal?: number | null;
    tax?: number | null;
    deliverySurcharge?: number | null;
    supplyChainFee?: number | null;
    shipping?: number | null;
    creditApplied?: number | null;
    discountsApplied?: number | null;
    handlingFee?: number | null;
    total?: number | null;
    amountSaved?: number | null;
  };
  validateShipping?: boolean;
  shippingError?: string | undefined | null;

  defaultCreditCard?: CamelCasedPropertiesDeep<ConvertWebApiDeclaredToActual<WCreditCardView>>;

  deletedCartItems?:
    | CamelCasedPropertiesDeep<ConvertWebApiDeclaredToActual<WGetCartView>>['items']
    | null;
};

const initialState = {
  details: {},
  busy: false,
  initialized: false,
  size: 0,
  items: [],
  totals: {},
  validateShipping: true,
  freeShipping: null, // temporary
};

const cartReducer: Reducer<CartState> = (state = initialState, action) => {
  if (requestCartAction.match(action) || requestUpdatedCartAction.match(action)) {
    return { ...state, busy: true };
  }
  if (receiveCartAction.match(action)) {
    return {
      ...state,
      busy: false,
      initialized: true,
      cartMessage: action.payload?.cartMessage,
      size: action.payload?.items?.length || 0,
      items: action.payload?.items || [],
      totals: action.payload?.totals ?? {},
      freeShipping: action.payload?.freeShipping ?? null,
    };
  }
  if (receiveUpdatedCartAction.match(action)) {
    return {
      ...state,
      cartMessage: action.payload?.cartMessage,
      details: action.payload?.details,
      items: action.payload?.items || [],
      promo: action.payload?.promo,
      totals: action.payload?.totals ?? {},
    };
  }
  if (receiveDeletedItemsAction.match(action)) {
    return { ...state, deletedCartItems: action.payload };
  }

  if (receiveInitialCartDetailsAction.match(action)) {
    return {
      ...state,
      cartMessage: action.payload?.cart?.cartMessage,
      details: action.payload?.cart?.details,
      promo: action.payload?.cart?.promo,
      totals: action.payload?.cart?.totals ?? {},
      checkoutData: action.payload?.checkoutData,
      cartId: action.payload?.checkoutData && action.payload.checkoutData.cartId,
    };
  }

  if (receivedSavedCreditCardsAction.match(action)) {
    const cards = action.payload;
    const defaultCreditCard = cards?.[0];
    return {
      ...state,
      defaultCreditCard,
    };
  }

  if (receiveRemoveDefaultCreditCard.match(action)) {
    return {
      ...state,
      defaultCreditCard: undefined,
    };
  }

  if (receiveShippingValidationAction.match(action)) {
    const validateShipping =
      action.payload &&
      !action.payload.allowBypass &&
      (!action.payload.data || !action.payload.data.isValidating);
    const shippingError = action.payload.data && action.payload.data.userMessage;
    return {
      ...state,
      shippingError,
      validateShipping,
    };
  }

  return state;
};

export default cartReducer;
