import _sortBy from 'lodash/sortBy';

import {
  requestQuestions,
  receiveQuestions,
  requestSignup,
  onboardingCompleted,
  updateQuestionNumber,
  updateWeddingDate,
  setOnboardAddToRegistryProductData,
  setOnboardAddToRegistryEventData,
  setOnboardRedirect,
  clearOnboardProductData,
  setSubmitDelayActive,
} from '@/actions/types/OnboardActionTypes';
import { AnyAction } from '@reduxjs/toolkit';

export const initialState = {
  questions: [],
  isSigningUp: false,
  hasSignedUp: false,
  questionsLoaded: false,
  currentQuestionNumber: 0,
  giftsAdded: 0,
  redirectTo: '',
  isRegistryRedirect: false,
  productData: {},
  eventData: {},
  submitDelayActive: false,
};

const onboardReducer = (state = initialState, action: AnyAction) => {
  if (receiveQuestions.match(action)) {
    // sort questions by their position
    // @ts-expect-error payload is not yet defined.
    const questions = _sortBy(action.payload.data.questions, ['position']);
    return {
      ...state,
      // @ts-expect-error payload is not yet defined.
      ...action.payload.data,
      questions,
      questionsLoaded: true,
    };
  }

  if (updateQuestionNumber.match(action)) {
    const questionNumber = action.payload;
    return { ...state, questionNumber };
  }

  if (requestQuestions.match(action)) {
    return { ...state, questionsLoaded: false };
  }
  if (requestSignup.match(action)) {
    return { ...state, isSigningUp: true };
  }

  if (onboardingCompleted.match(action)) {
    // @ts-expect-error payload is not yet defined.
    return { ...state, isSigningUp: false, hasSignedUp: true, ...action.payload.data };
  }
  if (updateWeddingDate.match(action)) {
    return { ...state, ...action.payload };
  }

  if (setOnboardAddToRegistryEventData.match(action)) {
    return { ...state, eventData: action.payload };
  }

  if (setOnboardAddToRegistryProductData.match(action)) {
    return { ...state, productData: action.payload };
  }
  if (setOnboardRedirect.match(action)) {
    const { path, isRegistry } = action.payload;
    return { ...state, redirectTo: path, isRegistryRedirect: isRegistry };
  }

  if (clearOnboardProductData.match(action)) {
    return {
      ...state,
      redirectTo: '',
      isRegistryRedirect: false,
      productData: {},
      eventData: {},
    };
  }

  if (setSubmitDelayActive.match(action)) {
    return { ...state, submitDelayActive: action.payload };
  }
  return state;
};

export default onboardReducer;
