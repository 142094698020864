import { AnyAction } from '@reduxjs/toolkit';
import {
  receivedCollectionInfoByRegistryId,
  receivedCollectionItemByCollectionId,
  receivedRegistryBySlug,
  receivedRegistryInfoByCollectionId,
  receiveRegistry,
  requestCollectionInfoByRegistryId,
  requestCollectionItemByCollectionId,
  requestRegistry,
  requestRegistryBySlug,
  requestRegistryInfoByCollectionId,
} from '../actions/types/RegistryActionTypes';

const initialState = {
  busy: false,
  collectionInfo: null,
  test: null,
};

export default function registryReducer(state = initialState, action: AnyAction) {
  if (requestRegistryBySlug.match(action)) {
    return { ...state, busy: true };
  }

  if (receivedRegistryBySlug.match(action)) {
    // @ts-expect-error payload is not yet defined.
    return { ...state, busy: false, ...action.payload };
  }
  if (requestCollectionItemByCollectionId.match(action)) {
    return { ...state, busy: true, collectionItem: null };
  }
  if (receivedCollectionItemByCollectionId.match(action)) {
    // @ts-expect-error payload is not yet defined.
    return { ...state, busy: false, collectionItem: action.payload.data };
  }
  if (requestRegistryInfoByCollectionId.match(action)) {
    return { ...state, busy: true, collectionItemInfo: null };
  }
  if (receivedRegistryInfoByCollectionId.match(action)) {
    // @ts-expect-error payload is not yet defined.
    return { ...state, busy: false, collectionItemInfo: action.payload.data };
  }

  if (requestCollectionInfoByRegistryId.match(action)) {
    return { ...state, busy: true, collectionInfo: null };
  }
  if (receivedCollectionInfoByRegistryId.match(action)) {
    return { ...state, busy: false, collectionInfo: action.payload };
  }
  if (requestRegistry.match(action)) {
    return { ...state, busy: true };
  }

  if (receiveRegistry.match(action)) {
    // @ts-expect-error payload is not yet defined.
    return { ...state, busy: false, ...action.payload };
  }
  return state;
}
